@charset "UTF-8";
/**
 * Base Fonts Configuration - CH
 */
/* sass-lint:disable no-duplicate-properties */
/* Everett - Regular (400) */
@font-face {
  font-family: 'Everett';
  font-style: normal;
  font-weight: 400;
  src: url("fonts/Everett-Regular.eot");
  src: url("/fonts/Everett-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/Everett-Regular.woff") format("woff"), url("/fonts/Everett-Regular.ttf") format("truetype");
}

/* Everett - Medium (500) */
@font-face {
  font-family: 'Everett';
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/Everett-Medium.eot");
  src: url("/fonts/Everett-Medium.eot?#iefix") format("embedded-opentype"), url("/fonts/Everett-Medium.woff") format("woff"), url("/fonts/Everett-Medium.ttf") format("truetype");
}

/* Everett - Bold (600) */
@font-face {
  font-family: 'Everett';
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/Everett-Bold.eot");
  src: url("/fonts/Everett-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Everett-Bold.woff") format("woff"), url("/fonts/Everett-Bold.ttf") format("truetype");
}

/* sass-lint:enable no-duplicate-properties */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #25282A;
  font-family: "Oswald", "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  line-height: 1.20833em;
}

@media (max-width: 767px) {
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
}

button:focus {
  outline-color: #F1625B;
}

.btn.btn-link {
  padding-top: 0;
  padding-bottom: 0;
  color: #F1625B;
  margin: 1em 0;
}

.btn.btn-link:after {
  background-color: #F1625B;
}

.btn.btn-link:hover {
  color: #E4554E;
}

.btn.btn-link:hover:after {
  background-color: #E4554E;
}

.btn.btn.btn-danger {
  border-color: #F1625B;
  background-color: #F1625B;
}

.btn.btn.btn-danger:hover, .btn.btn.btn-danger:focus, .btn.btn.btn-danger:active {
  border-color: #E4554E;
  background-color: #E4554E;
}

/**
 * Accordion Component — TJ
 */
.accordion .toggle-header .component .field-heading {
  font-family: "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  min-width: 230px;
  padding: 1.09375em 0.625em 1.09375em 0.625em;
  border-color: "transparent";
  background-color: "transparent";
  color: #f15a38;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 0;
}

.accordion .toggle-header .component .field-heading:after {
  background-color: transparent;
  background-image: url("../images/ico-zigzag-orange.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  content: '';
  display: inline-block;
  margin-left: 10px;
  margin-top: -1px;
  width: 1.5em;
  height: 10px;
  position: static;
  transform: translate(0%, 0%);
}

.accordion .toggle-header .component .field-heading:hover, .accordion .toggle-header .component .field-heading:focus {
  border-color: "transparent";
  background-color: "transparent";
  color: #f15a38;
}

.accordion .toggle-header .component .field-heading:hover:after, .accordion .toggle-header .component .field-heading:focus:after {
  transform: translate(-5px, 0%);
}

/**
 * Amenities & Facilities Component — CH
 */
.amenities .amenities-title {
  color: #f15a38;
}

/**
 * Breadcrumb Component
 */
.breadcrumb a {
  color: #43505A;
}

.breadcrumb.boxed {
  margin-left: -1em;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .breadcrumb.boxed {
    display: block;
    margin-left: 0;
  }
}

.breadcrumb.retail-directory.component {
  display: block;
}

@media (max-width: 991px) {
  .breadcrumb.retail-directory.component {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .breadcrumb.retail-directory.component .field-navigationtext {
    width: 240px;
    height: 50px;
    padding: 12px 20px 0;
    border: 1px solid #dbdbdb;
  }
  .breadcrumb.retail-directory.component .field-navigationtext a {
    color: #F1625B;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    font-family: "Oswald", "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  }
  .breadcrumb.retail-directory.component .field-navigationtext a:after {
    display: inline-block;
    position: absolute;
    top: 50%;
    width: 1.5em;
    height: 2px;
    transform: translateY(-50%);
    transition: transform 0.2s ease;
    background-color: #F1625B;
    content: '';
    will-change: transform;
  }
  .breadcrumb.retail-directory.component .field-navigationtext a:hover {
    color: #F1625B;
    text-decoration: none;
  }
  .breadcrumb.retail-directory.component .field-navigationtext a:hover:after {
    transform: translateX(-4px) translateY(-50%);
  }
}

.social-links a {
  color: #43505A;
}

/**
 * Build Stats / Attributes — TJ
 */
@media (max-width: 1199px) {
  .building-stats {
    padding: 0 20px;
  }
}

/**
 * Calendly Component — TJ
 */
.calendly-inline-widget.calendly-inline-widget.calendly-inline-widget.calendly-inline-widget {
  height: 750px !important;
}

/**
 * Carousel Component — CH
 */
.carousel .flexslider .flex-control-thumbs li.flex-active:before {
  background-color: #f15a38;
}

/**
 * Column Spliter Component
 */
.row.column-splitter .component-content img {
  width: 100%;
}

/**
 * fifty-fifty Component — TJ
 */
.fifty-fifty .component-content .column-splitter > div,
.fifty-fifty .component-content .column-splitter .magazine,
.fifty-fifty-left .component-content .column-splitter > div,
.fifty-fifty-left .component-content .column-splitter .magazine,
.fifty-fifty-right .component-content .column-splitter > div,
.fifty-fifty-right .component-content .column-splitter .magazine {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
}

.fifty-fifty .component-content .column-splitter .magazine,
.fifty-fifty-left .component-content .column-splitter .magazine,
.fifty-fifty-right .component-content .column-splitter .magazine {
  padding-bottom: 30px;
  padding-top: 30px;
}

.fifty-fifty .component-content .column-splitter .image,
.fifty-fifty .component-content .column-splitter .component-content,
.fifty-fifty .component-content .column-splitter a,
.fifty-fifty-left .component-content .column-splitter .image,
.fifty-fifty-left .component-content .column-splitter .component-content,
.fifty-fifty-left .component-content .column-splitter a,
.fifty-fifty-right .component-content .column-splitter .image,
.fifty-fifty-right .component-content .column-splitter .component-content,
.fifty-fifty-right .component-content .column-splitter a {
  height: 100%;
  width: 100%;
}

.fifty-fifty .component-content .column-splitter .rich-text,
.fifty-fifty-left .component-content .column-splitter .rich-text,
.fifty-fifty-right .component-content .column-splitter .rich-text {
  min-height: 400px;
}

@media (min-width: 1025px) {
  .fifty-fifty .component-content .column-splitter .rich-text.large-area,
  .fifty-fifty-left .component-content .column-splitter .rich-text.large-area,
  .fifty-fifty-right .component-content .column-splitter .rich-text.large-area {
    min-height: 600px;
  }
}

.fifty-fifty .component-content .column-splitter img,
.fifty-fifty-left .component-content .column-splitter img,
.fifty-fifty-right .component-content .column-splitter img {
  font-family: 'object-fit: cover;';
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.fifty-fifty-left .component-content .column-splitter > div:nth-of-type(1) {
  padding-right: 0;
}

.fifty-fifty-left .component-content .column-splitter > div:nth-of-type(2) {
  padding-left: 0;
}

@media (max-width: 991px) {
  .fifty-fifty-left .component-content .column-splitter > div:nth-of-type(1) {
    order: 2;
  }
  .fifty-fifty-left .component-content .column-splitter > div:nth-of-type(2) {
    order: 1;
  }
}

.fifty-fifty-right .component-content .column-splitter > div:nth-of-type(1) {
  order: 2;
  padding-left: 0;
}

.fifty-fifty-right .component-content .column-splitter > div:nth-of-type(2) {
  order: 1;
  padding-right: 0;
}

@media (max-width: 991px) {
  .fifty-fifty-right .component-content .column-splitter > div:nth-of-type(1) {
    padding-left: 15px;
  }
}

@media (max-width: 991px) {
  .fifty-fifty-right .row.column-splitter {
    padding: 0;
  }
}

@media (max-width: 1199px) {
  .fifty-fifty-right .row.column-splitter > div {
    padding: 0;
  }
}

/**
 * Floating Tile Component — CH
 */
.floating-tile .scEnabledChrome .floating-tile__background {
  background-color: #1E1E52;
}

/**
 * Floorplan Component — CONN & TJ
 */
.component-floorplan:before {
  background: #1E1E52;
}

.component-floorplan .align-level-select h1,
.component-floorplan .align-level-select .rw-widget .rw-list-option,
.component-floorplan .align-level-select .rw-widget .rw-dropdown-list-input,
.component-floorplan .level-nav h4,
.component-floorplan ul.level-list li a {
  font-family: "Oswald", "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
}

.component-floorplan .align-level-select h1 {
  margin-bottom: 0;
}

.component-floorplan ul.level-list li a:hover, .component-floorplan ul.level-list li a:focus {
  color: #f15a38;
}

.component-floorplan .level-detail .details dl dd {
  font-weight: 300;
}

.component-floorplan .level-detail .image-holder .image-launcher {
  background-color: #f15a38;
}

.component-floorplan .rw-dropdown-list-input {
  overflow: visible;
}

.component-floorplan .image-viewer img {
  font-family: 'object-fit: cover;';
}

@media (max-width: 1199px) {
  .component-floorplan .image-viewer button.close-button {
    top: 14.6em;
  }
}

@media (max-width: 768px) {
  .component-floorplan .image-viewer button.close-button {
    top: 9.6em;
  }
}

/**
 * Footer Component — CH + TJ
 */
#wrapper > footer {
  font-family: "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  /*@media(min-width: 992px) {
		.mirvac-logo {
			margin: 0 0 0 25px;
		}
	}*/
  /**
	 * Fix social link alignment inside IE
	 */
}

#wrapper > footer .row {
  align-items: center;
}

#wrapper > footer img {
  margin-left: 25px;
  height: 30px;
}

#wrapper > footer .social-links .row {
  justify-content: space-between;
  margin: auto;
  max-width: 170px;
}

#wrapper > footer .social-links .row .col a span {
  display: inline-block;
  width: 33px;
  height: 33px;
  padding: 0;
}

#wrapper > footer .social-links .row .col a span.icon-facebook {
  background-image: url("../images/icons/ico-round-fb.svg");
}

#wrapper > footer .social-links .row .col a span.icon-instagram {
  background-image: url("../images/icons/ico-round-insta.svg");
}

#wrapper > footer .social-links .row .col a span.icon-linkedin {
  background-image: url("../images/icons/ico-round-linkedin.svg");
}

#wrapper > footer .social-links .row .col a span.icon-youtube {
  background-image: url("../images/icons/ico-round-youtube.svg");
}

@media (max-width: 1000px) {
  #wrapper > footer .row {
    justify-content: center;
  }
  #wrapper > footer p {
    text-align: center !important;
  }
  #wrapper > footer .link-list {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;
  }
  #wrapper > footer .component.image {
    margin: 15px 0;
  }
}

#wrapper > footer .link-list > .component-content li a {
  padding-left: 0;
}

/**
 * Forms Component — CH
 */
.form {
  font-size: 1.8em;
}

.form input[type='submit'],
.form button {
  font-family: "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  font-size: 1.6rem;
  line-height: 1.3125em;
  text-transform: none;
  min-width: 230px;
  padding: 1.09375em 0.625em 1.09375em 0.625em;
  border-color: #F1625B;
  background-color: #F1625B;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  transition: background 0.2s linear, box-shadow 0.4s ease;
}

.form input[type='submit']:hover, .form input[type='submit']:focus,
.form button:hover,
.form button:focus {
  border-color: #E4554E;
  background-color: #E4554E;
  color: white;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.31);
}

.form .form-submit-border.form-group:after {
  background-color: white;
  content: '';
  display: inline-block;
  margin-top: -1px;
  width: 1.5625em;
  height: 2px;
  position: absolute;
  right: 40px;
}

@media (max-width: 767px) {
  .form .form-submit-border.form-group:after {
    display: none;
  }
}

.form .page-header h1 {
  font-size: 4em;
  font-weight: 600;
  line-height: 1.30556em;
  letter-spacing: normal;
}

@media (max-width: 767px) {
  .form .page-header h1 {
    font-size: 2.125em;
    line-height: 1.29412em;
  }
}

.form .page-header h2 {
  font-size: 2.33333em;
  font-weight: 500;
  line-height: 1.5em;
  letter-spacing: normal;
}

@media (max-width: 767px) {
  .form .page-header h2 {
    font-size: 1.75em;
    line-height: 1.35714em;
  }
}

.form .page-header h3 {
  padding-top: 0;
  font-size: 2.11111em;
  font-weight: 300;
  line-height: 1.31579em;
  letter-spacing: normal;
}

@media (max-width: 767px) {
  .form .page-header h3 {
    font-size: 1.5em;
    line-height: 1.33333em;
  }
}

.form .page-header h4,
.form .page-header h5,
.form .page-header h6 {
  padding-top: 0;
  font-size: 1.55556em;
  font-weight: 300;
  line-height: 1.32143em;
  letter-spacing: normal;
}

@media (max-width: 767px) {
  .form .page-header h4,
  .form .page-header h5,
  .form .page-header h6 {
    font-size: 1.25em;
    line-height: 1.3em;
  }
}

/**
 * Hero Header Component — CH
 */
.full-screen-background__overlayimage.fadein, .full-screen-background__overlaytext.fadein {
  opacity: 1;
  z-index: 1;
}

.full-screen-background__overlaytext h1, .full-screen-background__content h1 {
  font-family: "Oswald", "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.full-screen-background__overlaytext h1 span, .full-screen-background__content h1 span {
  background-color: white;
  width: 12rem;
  height: 2px;
}

@media (max-width: 991px) {
  .full-screen-background__overlaytext, .full-screen-background__content {
    padding: 43px 25px 25px;
  }
}

.full-screen-background .full-screen-background__container:before {
  background-color: rgba(67, 80, 90, 0.5);
}

.full-screen-background__overlayimage + .full-screen-background__overlaytext:before {
  background-color: white;
  width: 18rem;
  height: 2px;
}

@media (min-width: 992px) {
  .full-screen-background__overlayimage + .full-screen-background__overlaytext:before {
    transform: rotate(90deg) translateX(20%);
    margin-left: -80px;
    top: 56px;
  }
}

.full-screen-background__content {
  top: 60%;
}

@media (min-width: 992px) {
  .full-screen-background__content h1 {
    line-height: 1.30556em;
  }
}

/**
 * Image Gallery Component — TJ
 */
.property-image-gallery .bg-gradient {
  position: relative;
}

.property-image-gallery .bg-gradient:before {
  background-color: rgba(0, 0, 0, 0.2);
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.property-image-gallery .bg-gradient p, .property-image-gallery .bg-gradient a {
  text-shadow: 0px 0px 5px black;
}

.property-image-gallery p {
  position: relative;
  font-weight: 300;
}

.property-image-gallery .image-gallery__container p.image-gallery__header {
  font-family: "Oswald", "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  font-weight: 700;
}

.property-image-gallery .btn.btn.btn-link {
  color: white;
  min-width: auto;
}

.property-image-gallery .btn.btn.btn-link:hover:after {
  background-color: #fff;
}

.property-image-gallery .btn.btn.btn-link:after {
  background-image: url(../images/ico-zigzag-white.svg);
}

/**
 * Interactive Map Component — CONN & CH & TJ
 */
.component-interactive-map .filter-bar.introScreenfalse {
  background: #F1625B !important;
}

.component-interactive-map .filter-bar h1 {
  font-family: "Oswald", "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  text-transform: uppercase;
}

.component-interactive-map .filter-bar .btn:after {
  background: transparent !important;
  background-image: url("../images/ico-zigzag-white.svg") !important;
  background-repeat: no-repeat !important;
  transition: all 0.2s linear;
}

.component-interactive-map .filter-bar .btn:hover:after {
  background-image: url("../images/ico-zigzag-orange.svg") !important;
  transition: all 0.2s linear;
}

.component-interactive-map.intro-screen-true .filter-bar {
  background-color: #F1625B;
}

.component-interactive-map.intro-screen-true .filter-bar .align {
  max-width: 540px;
}

.component-interactive-map.intro-screen-true .filter-bar .btn {
  font-family: "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
}

.component-interactive-map .pane .place-card h1 {
  font-family: "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
}

.component-interactive-map div[style*='background-color: rgb(151, 123, 89)'] {
  background-color: #F1625B !important;
}

.component-interactive-map .btn.btn.btn-link:after {
  height: 10px;
}

/**
 * Link List Component — CH
 */
.link-list > .component-content li a {
  color: #474748;
}

.link-list > .component-content li a:hover, .link-list > .component-content li a:focus {
  color: #f15a38;
}

/**
 * Load More Component — TJ
 */
.component.load-more {
  padding-top: 7rem;
}

.component.load-more .component-content input[type='button'] {
  transition: all 0.3s ease;
  font-family: "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.3125em;
  text-transform: none;
  min-width: 230px;
  padding: 1.09375em 0.625em 1.09375em 0.625em;
  border-color: #f15a38;
  background-color: #f15a38;
  color: #1E1E52;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase !important;
}

.component.load-more .component-content input[type='button']:hover, .component.load-more .component-content input[type='button']:focus {
  border-color: #f15a38;
  background-color: #f15a38;
  color: #1E1E52;
}

/**
 * Logo Grid Component — CH
 */
.col-xl-6 .component .logo-grid .image {
  flex-basis: 15%;
}

.component .logo-grid .image {
  flex-basis: 100%;
  max-width: 150px;
}

@media (max-width: 1043px) {
  .component .logo-grid .image {
    max-width: 130px;
  }
}

@media (max-width: 903px) {
  .component .logo-grid .image {
    max-width: 110px;
  }
}

@media (max-width: 776px) {
  .component .logo-grid .image {
    max-width: 100px;
  }
}

.component .logo-grid .image .image-caption {
  color: #1E1E52;
}

.mmopen .nav-wrapper,
.home .mmopen .nav-wrapper {
  background-image: url(../images/logo-riverside-quay-black.svg);
  background-size: 119px;
  background-position: 20px 30px;
}

/**
 * Navigation Component — TJ
 */
header .nav-wrapper {
  background-color: #F1625B;
}

@media (max-width: 767px) {
  header .nav-wrapper.homesticky {
    background: #F1625B;
  }
}

@media (max-width: 991px) {
  header .nav-wrapper.homesticky .search-toggle.search-toggle.search-toggle a {
    top: 1px !important;
  }
}

@media (max-width: 991px) {
  header .nav-wrapper.homesticky .search-box.search-box.search-box {
    top: 45px !important;
  }
}

@media (max-width: 991px) {
  header .nav-wrapper .search-box.search-box.search-box {
    top: 45px !important;
  }
}

header .nav-wrapper .image {
  flex-basis: 17em;
  padding-top: 2.3em;
  height: 6.8em;
}

@media (max-width: 1469px) {
  header .nav-wrapper .image {
    padding-left: 2em;
  }
}

@media (max-width: 1469px) and (max-width: 991px) {
  header .nav-wrapper .image {
    padding-left: 2em;
    flex-basis: 16em;
  }
}

@media (max-width: 991px) {
  header .nav-wrapper .mirvac-logo {
    display: none;
  }
}

header .logo.logo.logo img {
  max-height: 30px;
  width: 140px;
}

@media (max-width: 1469px) {
  header .logo.logo.logo img {
    width: 136px;
  }
}

@media (max-width: 1469px) and (max-width: 991px) {
  header .logo.logo.logo img {
    width: 140px;
  }
}

@media (max-width: 991px) {
  header .component.navigation .component-content {
    padding-right: 0px !important;
  }
}

header .component.navigation nav ul li:not(:first-child) {
  border-left: 0;
}

header .component.navigation nav ul .level1 > a,
header .component.navigation nav ul .level1 > .field-navigationtitle > a {
  font-family: "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  text-transform: none;
  font-size: 1.8em;
}

header .component.navigation.site-nav .dropdown .image-text {
  background-color: transparent;
}

header .component.navigation.site-nav .dropdown .image-text .content h3 {
  font-family: "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  text-transform: none;
}

header .component.navigation.site-nav .dropdown .image-text img {
  opacity: 1;
}

header .component.navigation.site-nav .dropdown > ul.clearfix {
  /**
					 * Change fonts to VW units on smaller screens to help with overflowing items
					 */
}

header .component.navigation.site-nav .dropdown > ul.clearfix li {
  margin-bottom: 1.5vh;
}

header .component.navigation.site-nav .dropdown > ul.clearfix li a {
  color: #25282A;
}

header .component.navigation.site-nav .dropdown > ul.clearfix a:focus {
  color: #f15a38 !important;
}

header .component.navigation.site-nav .dropdown > ul.clearfix li.level2,
header .component.navigation.site-nav .dropdown > ul.clearfix ul.clearfix a {
  font-family: "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
}

header .component.navigation.site-nav .dropdown > ul.clearfix li.level2 {
  font-size: 3.1em;
}

header .component.navigation.site-nav .dropdown > ul.clearfix .level3 {
  margin-bottom: 0;
}

header .component.navigation.navigation-global .search-toggle a {
  width: 1.4em;
}

/**
 * Page Selector (Pagination) Component — TJ
 */
.component.page-selector {
  background: #eeeeee;
}

.component.page-selector .page-selector-list a,
.component.page-selector .page-selector-list a:after {
  color: #474748;
  transition: all 0.3s ease;
}

.component.page-selector .page-selector-list a:hover,
.component.page-selector .page-selector-list a:focus,
.component.page-selector .page-selector-list a.active {
  color: #f15a38;
}

.component.page-selector .page-selector-item-previous a:before {
  background: url(../images/ico-left-arrow-grey.svg);
  content: '';
  display: inline-block;
  height: 18px;
  width: 8px;
  transition: all 0.3s ease;
}

.component.page-selector .page-selector-item-previous a:hover:before {
  background: url(../images/ico-left-arrow-red.svg);
}

.component.page-selector .page-selector-item-next a:after {
  background: url(../images/ico-right-arrow-grey.svg);
  content: '';
  display: inline-block;
  height: 18px;
  width: 8px;
  transition: all 0.3s ease;
}

.component.page-selector .page-selector-item-next a:hover:after {
  background: url(../images/ico-right-arrow-red.svg);
}

.component.page-selector .page-selector-item-previous.inactive,
.component.page-selector .page-selector-item-next.inactive {
  visibility: hidden;
}

.component.page-selector.page-selector-single-page {
  display: none;
}

.content,
.rich-text {
  color: #25282A;
  font-weight: 300;
  font-size: 1.8em;
  line-height: 1.5em;
}

.content h2, .content h3,
.rich-text h2,
.rich-text h3 {
  margin-top: 7.8rem;
  margin-bottom: 3.9rem;
}

.content p,
.rich-text p {
  margin-bottom: 3rem;
}

.content.white-text ul li:before,
.rich-text.white-text ul li:before {
  background-color: white;
}

.content a,
.rich-text a {
  color: #25282A;
  font-size: 1em;
}

.content blockquote,
.rich-text blockquote {
  color: #F1625B;
  font-family: "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  font-size: 1.33333em;
  line-height: 1.5em;
  font-weight: 300;
}

.content blockquote p:first-of-type,
.rich-text blockquote p:first-of-type {
  margin-top: 0;
}

.content blockquote p:last-of-type,
.rich-text blockquote p:last-of-type {
  margin-bottom: 0;
}

.content cite,
.rich-text cite {
  font-family: "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  font-size: 0.77778em;
  line-height: 1.57143em;
  font-weight: 300;
  margin: -4.5em 0 4.8em 3.8em;
  display: block;
  color: #25282A;
}

.content cite:before,
.rich-text cite:before {
  content: '';
  background-image: url("../images/icon-quote.svg");
  font-family: "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  line-height: 0.2em;
  font-size: 7.5em;
  color: #F1625B;
  display: block;
  margin-top: 0.75em;
  margin-bottom: 0.17em;
  width: 32px;
  height: 26px;
}

.content ul li:before,
.rich-text ul li:before {
  background-color: #25282A;
}

.magazine h3 {
  font-family: "Oswald", "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
}

/**
 * Search Results Component — TJ
 */
.search-results.search-results--main .search-result-list > li {
  flex-basis: 100%;
}

.search-results.search-results--main .search-result-list > li .field-title {
  font-family: "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
}

.search-results.search-results--main .search-result-list > li .field-title a:hover,
.search-results.search-results--main .search-result-list > li .field-title a:focus {
  color: #f15a38;
}

.component.load-more .component-content input[type='button'] {
  transition: all 0.3s ease;
  font-family: "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.3125em;
  text-transform: none;
  min-width: 230px;
  padding: 1.09375em 0.625em 1.09375em 0.625em;
  border-color: #f15a38;
  background-color: #f15a38;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.component.load-more .component-content input[type='button']:hover, .component.load-more .component-content input[type='button']:focus {
  border-color: #E4554E;
  background-color: #E4554E;
  color: white;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.31);
}

ul.search-result-list article .hgroup h2 {
  font-family: "Oswald", "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
}

/**
 *  Summary mode - used on the home page
 */
.summary-mode ul.search-result-list {
  background-color: #F1625B;
}

@media (max-width: 1191px) {
  .summary-mode ul.search-result-list {
    height: auto;
    overflow: auto;
  }
}

.search-results.search-results {
  /**
	 * Flip the image to the right and events to the left
	 */
}

.search-results.search-results.event-r-l {
  /**
		 * Hide events after three - more come through than we want/need
		 */
  /**
		 * Flip the image to the right
		 */
}

.search-results.search-results.event-r-l ul li:nth-child(n+4) {
  display: none;
}

@media (min-width: 1191px) {
  .search-results.search-results.event-r-l ul li:nth-of-type(1) {
    order: 3;
  }
}

header .component.navigation.navigation-global .search-toggle .component.search-box.horizontal input::-ms-clear {
  display: none;
}

/**
 * Short Header Component — TJ
 */
.short-header.excitement .full-screen-background__container::before {
  display: none;
}

.short-header.excitement .full-screen-background__content {
  color: #1E1E52;
}

.short-header.excitement .full-screen-background__content h1 span {
  background-image: url("../images/ico-divider-zigzag-purple.svg");
}

/**
 * Statistics Component — TJ
 */
section.statistics article.stat .hgroup .number {
  color: #25282A;
  font-weight: 700;
}

section.statistics article.stat .hgroup .label {
  color: #f15a38;
  font-weight: 700;
  padding-top: 0;
}

/**
 * Store Detail Component
 */
.store-detail {
  font-size: 2em;
}

.store-detail.container {
  padding: 0 15px;
}

.store-detail .store-info {
  padding: 1.5em;
}

.store-detail .store-info h1 {
  text-transform: uppercase;
}

.store-detail .store-info .map {
  height: 500px !important;
}

.store-detail .store-info.boxed > div {
  margin-left: 0 !important;
}

@media (max-width: 991px) {
  .store-detail .store-info {
    padding: 0;
  }
}

.store-detail .mv-icon {
  display: none;
  width: 1.1em;
  height: 1.1em;
  background-size: cover;
  opacity: 0.6;
  margin-top: -0.2em;
}

.store-detail .store-attributes {
  border: 1px solid #bbbcbc;
  margin-top: 4em;
  text-align: center;
}

.store-detail .store-attributes h2,
.store-detail .store-attributes .centre-hours__store-header {
  text-transform: uppercase;
  font-size: 1em;
  font-family: "Oswald", "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  font-weight: 400;
  color: #25282A;
  margin-top: 7.8rem;
  margin-bottom: 3.9rem;
}

.store-detail .store-offer {
  border-bottom: 1px solid #bbbcbc;
}

.store-detail .store-offer-detail {
  font-size: 0.9em;
}

.store-detail .store-offer:before {
  display: block;
  content: '';
  background-image: url("/-/media/Themes/Mirvac/OandI/OandI-Base-Site/OandI-Base-Site-Theme/images/icons/icon-offers-tag--white.svg");
  background-color: #bbbcbc;
  background-size: 2em;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 3em;
  height: 3em;
  width: 3em;
  margin: -1.5em auto;
}

.store-detail .store-hours {
  border-bottom: 1px solid #bbbcbc;
  padding: 1.5em 0;
}

.store-detail .store-hours .centre-hours__day {
  float: left;
  padding-left: 2em;
  width: 155px;
  text-align: left;
}

@media (max-width: 390px) {
  .store-detail .store-hours .centre-hours__day {
    float: none;
  }
}

@media (min-width: 1200px) {
  .store-detail .store-hours .centre-hours__day {
    float: none;
  }
}

@media (min-width: 1470px) {
  .store-detail .store-hours .centre-hours__day {
    float: none;
  }
}

@media (min-width: 1630px) {
  .store-detail .store-hours .centre-hours__day {
    float: left;
  }
}

.store-detail .store-hours .centre-hours__open-hours {
  text-align: left;
  padding-left: 2em;
}

.store-detail .store-hours .centre-hours__circ {
  clear: both;
  margin: 12px 0;
}

.retail-directory.breadcrumb.component {
  padding-left: 4.5rem;
}

.retail-directory.breadcrumb.component a:hover {
  text-decoration: underline;
  color: #77787c;
}

#retail .container.container, .store-detail.container {
  /*CONN: Ugly but necessary override */
}

@media (min-width: 576px) {
  #retail .container.container, .store-detail.container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  #retail .container.container, .store-detail.container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  #retail .container.container, .store-detail.container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  #retail .container.container, .store-detail.container {
    max-width: 1140px;
  }
}

@media (min-width: 1470px) {
  #retail .container.container, .store-detail.container {
    max-width: 1600px;
  }
}

.property-index__view__toggle.active {
  color: #F1625B;
}

/**
 * Team Members Component — CH
 */
.team-members__grid .team-member__details__contact .team-member__details__phone {
  transition: all 0.3s ease;
  font-weight: 400;
  color: #474748;
}

.team-members__grid .team-member__details__contact .team-member__details__phone:hover, .team-members__grid .team-member__details__contact .team-member__details__phone:focus {
  color: #f15a38;
  cursor: pointer;
}

.team-members__grid .team-member__details__contact .team-member__details__phone:before {
  background-image: url("../images/icon-mobile.svg");
  background-size: 10px auto;
}

.team-members__grid .team-member__details__contact .team-member__details__email {
  transition: all 0.3s ease;
  font-weight: 400;
  color: #474748;
}

.team-members__grid .team-member__details__contact .team-member__details__email:hover, .team-members__grid .team-member__details__contact .team-member__details__email:focus {
  color: #f15a38;
}

.team-members__grid .team-member__details__contact .team-member__details__email:before {
  background-image: url("../images/icon-email.svg");
  background-size: 17px auto;
}

/**
 * Timeline Component — TJ
 */
.component-timeline .timeline-card {
  background-color: #43505A;
}

.component-timeline .timeline-card .copy-holder span.year,
.component-timeline .timeline-card .copy-holder span.date,
.component-timeline .timeline-card .copy-holder h1 {
  font-family: "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  text-transform: none;
}

.component-timeline .timeline-card .copy-holder span.year {
  line-height: 1.2;
  font-family: "Oswald", "Roboto", Helvetica, Verdana, Tahoma, sans-serif;
  font-weight: 700;
}

.component-timeline .timeline-card .copy-holder span.date {
  margin-top: 0;
  padding: 1.5vh 0 3vh;
}

.component-timeline .timeline-card .copy-holder span.date:after {
  bottom: 0;
}

.item-open-false .arrow {
  display: none !important;
}

/**
 * Page Title Component — CH
 */
.title {
  background: none;
}

.title h2 {
  margin: 0;
}

/**
 * Twitter Feed Component — CH
 */
.twitter {
  font-weight: 400;
}

.twitter .tweet-datetime {
  color: #707070;
}

.twitter .tweet-actions a {
  color: #707070;
}

.twitter .twitter-follow-button {
  margin-bottom: 30px;
}

.twitter .btn.btn.btn.btn-link {
  color: white;
  justify-content: center;
}

.twitter .btn.btn.btn.btn-link:after {
  background-image: url("../images/ico-zigzag-white.svg");
  margin-right: 0;
}
